import React from 'react'

import './portfolio.scss';
import Panel1 from '../Panels/Panel1';
import Panel2 from '../Panels/Panel2';
import Panel3 from '../Panels/Panel3';

const Portfolio = () => {
  return (
    <div className='portfolio__block'>
        <h1>Portfolio</h1>
        <div className="projects">
          <Panel1 />
          <Panel2 />
          <Panel3 />

    </div>
        
    </div>
  )
}

export default Portfolio