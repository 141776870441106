import React, {useState} from 'react';
import './panels.scss';
import 'react-sliding-side-panel/lib/index.css';
import SlidingPanel from 'react-sliding-side-panel';
import IMG1 from '../../assets/images/SpellingBees/spelling_bees_home.png';

import Slideshow from '../Slideshows/SlideShow1';

import { FaPhp, FaUnity, FaFigma, FaHtml5, FaCss3      } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io";
import { TbSql } from "react-icons/tb";




const Panel1 = () => {
    const [openPanel, setOpenPanel] = useState(false);
  return (
    <div>
    <div>
      <button onClick={() => setOpenPanel(true)} className='openPanel'>
        <img src={IMG1} alt='menu'/>
        <h2>Spelling Bee'z (Honours Project)</h2>
      </button>
    </div>
    <SlidingPanel
      type={'right'}
      isOpen={openPanel}
      size={40}
    >
      <div>
        <div className='panelContent'>
        
            <div className="panel-header">
            <button onClick={() => setOpenPanel(false)} className='closeBtn'>X</button>
                <h1 className='header'>Spelling Bee'z (Honours Project)</h1>
                <Slideshow className="slideshow"/>
                <div className="panel-desc">
                    <p className='project-info'>For my final year at Uni, I had the opportunity to revisit a module I had completed the previous year and fully develop the project from the original designs to a fully functioning website. My Honours project investigated the effects of digital game-based learning in early childhood and whether learners developed reading and language skills better from games than from the traditional means of teaching. The practical side of the project was to create a fully functioning website that parents and children could use to play games and learn new words. Whilst the website is active and usable, it still has room for improvement. </p>
                </div>
                <div className="tools-used">
                    
                    <div className="tools">
                        <FaPhp className="tool"/>
                        <IoLogoJavascript className="tool"/>
                        <FaUnity className="tool"/>
                        <FaFigma className="tool"/>
                        <FaHtml5 className="tool"/>
                        <FaCss3 className="tool"/>
                        <TbSql className="tool"/>
                    </div>
                </div>
                <div className="links">
                  <a href="https://spellingbeez.co.uk/" className='link'>Live Demo</a>
                  <a href="https://github.com/CDicksonNapier/spellingBeez" className='link-to'> GitHub</a>
                </div>
            </div>
        



        </div>
       
      </div>
    </SlidingPanel>
  </div>
  )
}

export default Panel1