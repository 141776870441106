import React from "react";


import "./slideshow.scss";

import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css'

import IMG1 from '../../assets/images/notflix_logo.png';


const spanStyle = {
    padding: '10px',
    background: '#1f1f38',
    opacity: '1',
    backdropFilter: 'blur(60px)',
    textAlign: 'center',
    width: '100%',
    fontSize: '1rem',
    color: 'white'
  }
  
  const divStyle = {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '500px'

  }
 

const slideImages = [
    {
        url: IMG1,
        caption: 'logo'
      }
];

const Slideshow3 = () => {
    return (
      <div className="slide-container">
        <Slide easing="ease">
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                <span style={spanStyle}>{slideImage.caption}</span>
              </div>
            </div>
          ))} 
        </Slide>
      </div>
    )
}

export default Slideshow3;