import React from "react";


import "./slideshow.scss";

import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css'

import IMG1 from '../../assets/images/SpellingBees/home.png';
import IMG2 from '../../assets/images/SpellingBees/About.png';
import IMG3 from '../../assets/images/SpellingBees/homeScreenMid.png';
import IMG4 from '../../assets/images/SpellingBees/gameScreenMid.png';
import IMG5 from '../../assets/images/SpellingBees/Play.png';
import IMG6 from '../../assets/images/SpellingBees/spelling_bees_home.png';
import IMG7 from '../../assets/images/SpellingBees/ParentsPageHifi.png';
import IMG8 from '../../assets/images/SpellingBees/GamesScreen.png';

const spanStyle = {
    padding: '10px',
    background: '#1f1f38',
    opacity: '1',
    backdropFilter: 'blur(60px)',
    textAlign: 'center',
    width: '100%',
    fontSize: '1rem',
    color: 'white'
  }
  
  const divStyle = {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '500px'

  }
 

const slideImages = [
    {
        url: IMG1,
        caption: 'Home Page wireframe'
      },
      {
        url: IMG2,
        caption: 'About Page wireframe'
      },
      {
        url: IMG3,
        caption: 'Homepage Mockup'
      },
      {
        url: IMG4,
        caption: 'Game Screen Mockup'
      },
      {
        url: IMG5,
        caption: 'Game Screen Wireframe'
      },
      {
        url: IMG6,
        caption: 'Home Page High-Fidelity Mockup'
      },
      {
        url: IMG7,
        caption: 'Parents Page High-Fidelity Mockup'
      },
      {
        url: IMG8,
        caption: 'Games list page High-Fidelity Mockup'
      },
];

const Slideshow = () => {
    return (
      <div className="slide-container">
        <Slide easing="ease">
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                <span style={spanStyle}>{slideImage.caption}</span>
              </div>
            </div>
          ))} 
        </Slide>
      </div>
    )
}

export default Slideshow;