import React, { useState } from "react";
import "./panels.scss";
import "react-sliding-side-panel/lib/index.css";
import SlidingPanel from "react-sliding-side-panel";
import IMG1 from "../../assets/images/Art-folio-designs/High-Level-Design.png";

import Slideshow2 from '../Slideshows/Slideshow2';

import { FaFigma, FaHtml5, FaCss3, FaReact, FaSass } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io";

const Panel1 = () => {
  const [openPanel, setOpenPanel] = useState(false);
  return (
    <div>
      <div>
        <button onClick={() => setOpenPanel(true)} className="openPanel">
          <img src={IMG1} alt="menu" />
          <h2>Art Portfolio Refresh</h2>
        </button>
      </div>
      <SlidingPanel type={"right"} isOpen={openPanel} size={40}>
        <div>
          <div className="panelContent">
            <div className="panel-header">
              <button onClick={() => setOpenPanel(false)} className="closeBtn">
                X
              </button>
              <h1 className="header">Art Portfolio Refresh</h1>
              <Slideshow2 className="slideshow" />
              <div className="panel-desc">
                <p className="project-info">
                  In my 2nd year of Uni, one of my modules included revisiting
                  one of my past projects. The choice for this was to revisit my
                  first year art portfolio that I created way back in my first
                  year of college. The original project was a very basic
                  HTML/CSS and JS and although well received it wasn't where I
                  believed it could be. Seizing the opportunity to re-develop
                  it, I got to use tools that I have learned over my college and
                  university experience.{" "}
                </p>
              </div>
              <div className="tools-used">
                <div className="tools">
                  <FaFigma className="tool" />
                  <FaHtml5 className="tool" />
                  <FaCss3 className="tool" />
                  <IoLogoJavascript className="tool" />
                  <FaReact className="tool" />
                  <FaSass className="tool" />
                </div>
              </div>
              <div className="links">
                <a
                  href="https://cdicksonart.co.uk/"
                  className="link"
                  target="_blank"
                >
                  Live Demo
                </a>
                <a
                  href="https://github.com/CDicksonNapier/"
                  className="link-to"
                  target="_blank"
                >
                  {" "}
                  GitHub
                </a>
              </div>
            </div>
          </div>
        </div>
      </SlidingPanel>
    </div>
  );
};

export default Panel1;
