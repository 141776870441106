import React, { useState } from "react";
import "./panels.scss";
import "react-sliding-side-panel/lib/index.css";
import SlidingPanel from "react-sliding-side-panel";
import IMG1 from "../../assets/images/notflix_logo.png";

import Slideshow3 from '../Slideshows/Slideshow3';

// import { FaFigma, FaHtml5, FaCss3, FaReact, FaSass } from "react-icons/fa";
// import { IoLogoJavascript } from "react-icons/io";

const Panel3 = () => {
  const [openPanel, setOpenPanel] = useState(false);
  return (
    <div>
      <div>
        <button onClick={() => setOpenPanel(true)} className="openPanel">
          <img src={IMG1} alt="menu" id="not-flix-logo"/>
          <h2>NotFlix (Netflix Clone)</h2>
        </button>
      </div>
      <SlidingPanel type={"right"} isOpen={openPanel} size={40}>
        <div>
          <div className="panelContent">
            <div className="panel-header">
              <button onClick={() => setOpenPanel(false)} className="closeBtn">
                X
              </button>
              <h1 className="header">NotFlix (Netflix Clone)</h1>
              <Slideshow3 className="slideshow" />
              <div className="panel-desc">
                <p className="project-info">
                  I am currently working on this project. Originally, it was an element from 1st year at Uni that I had to build a web based app using python and flask. However, I have chosen to completely redo the entire website using React. 
                </p>
              </div>
              <div className="tools-used">
                <div className="tools">
                  {/* <FaFigma className="tool" />
                  <FaHtml5 className="tool" />
                  <FaCss3 className="tool" />
                  <IoLogoJavascript className="tool" />
                  <FaReact className="tool" />
                  <FaSass className="tool" /> */}
                </div>
              </div>
              <div className="links">
                <a
                  href=""
                  className="link"
                  target="_blank"
                >
                  Live Demo
                </a>
                <a
                  href="https://github.com/CDicksonNapier/"
                  className="link-to"
                  target="_blank"
                >
                  {" "}
                  GitHub
                </a>
              </div>
            </div>
          </div>
        </div>
      </SlidingPanel>
    </div>
  );
};

export default Panel3;
